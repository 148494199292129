import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { ProtocolProvider } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useProtocolNavigation } from 'client/app/apps/protocols/useProtocolNavigation';
import Button from 'common/ui/components/Button';

type Props = {
  id: ProtocolId;
  version: ProtocolVersion;
};

const EditProtocolScreen: React.FC<Props> = ({ id, version }: Props) => {
  const { Content, previous, next } = useProtocolNavigation();

  return (
    <ProtocolProvider protocolId={id} version={version}>
      <Content />
      <Footer>
        <Button variant="secondary" onClick={previous.onClick}>
          {previous.label}
        </Button>
        <Button variant="primary" color="primary" onClick={next.onClick}>
          {next.label}
        </Button>
      </Footer>
    </ProtocolProvider>
  );
};
export default EditProtocolScreen;

const Footer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'sticky',
  bottom: 0,
  gap: theme.spacing(5),
  padding: theme.spacing(4, 6),
}));
