import React, { useEffect, useRef, useState } from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import MuiCard from '@mui/material/Card';
import MuiCardActions from '@mui/material/CardActions';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';
import { SynthaceBrandmark } from 'common/assets/SynthaceBrandmark';
import { formatDateTime } from 'common/lib/format';
import stopPropagation from 'common/lib/stopPropagation';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import CustomLiquidPolicyIcon from 'common/ui/icons/CustomLiquidPolicyIcon';

type Props = {
  policy: LiquidPolicy;
  selected?: boolean;
  onClick?: (policy: LiquidPolicy) => void;
  onDoubleClick?: (policy: LiquidPolicy) => void;
  onViewDetails?: (policy: LiquidPolicy) => void;
};

export default function LiquidPolicyCard({
  policy,
  selected = false,
  onClick,
  onDoubleClick,
  onViewDetails,
}: Props) {
  const scrollIntoCard = useScrollIntoCard(selected);

  const avatarIcon =
    policy.source === LiquidPolicySourceEnum.user ? (
      <CustomLiquidPolicyIcon />
    ) : (
      <SynthaceBrandmark logoColour="multicolor" />
    );

  return (
    <Card
      ref={scrollIntoCard}
      onClick={() => onClick?.(policy)}
      onDoubleClick={() => onDoubleClick?.(policy)}
      selected={selected}
    >
      <CardHeader avatar={avatarIcon} title={<Title variant="h3">{policy.name}</Title>} />
      <CardContent>
        <Tooltip title={policy.description}>
          <Description variant="caption">
            {policy.description?.substring(0, 120) + '...'}
          </Description>
        </Tooltip>

        <Stack gap={2}>
          <Author>
            <Typography variant="body1">Created by:</Typography>
            <Typography variant="body1" fontWeight={500}>
              {policy.createdBy?.displayName ?? 'Synthace'}
            </Typography>
          </Author>
          <UpdateDate>
            <Typography variant="body1">Last updated:</Typography>
            <Typography variant="body1" fontWeight={500}>
              {formatDateTime(new Date(policy.createdAt))}
            </Typography>
          </UpdateDate>
        </Stack>
      </CardContent>
      <CardActions>
        <ViewButton
          onClick={event => {
            stopPropagation(event);
            onViewDetails?.(policy);
          }}
          onDoubleClick={stopPropagation}
        >
          <Typography variant="body1" color="textSecondary" textTransform="uppercase">
            View details
          </Typography>
        </ViewButton>
      </CardActions>
    </Card>
  );
}

/**
 * Scrolls into the card view if policy card is selected.
 *
 * Works only on mount.
 */
function useScrollIntoCard(selected: boolean) {
  const [mounted, setMounted] = useState(false);
  const cardRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (!mounted && selected) {
      cardRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
    setMounted(true);
  }, [mounted, selected]);
  return (ref: HTMLElement | null) => (cardRef.current = ref);
}

//#region Styles

const Card = styled(MuiCard, { shouldForwardProp: prop => prop !== 'selected' })<{
  selected: boolean;
}>(({ theme, selected }) => ({
  width: 325,
  height: 244,
  margin: 0,

  outline: selected ? `1px solid ${theme.palette.primary.main}` : 'none',

  cursor: 'default',
  userSelect: 'none',
}));
const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(6, 6, 3),
}));
const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(1, 6, 6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));
const CardActions = styled(MuiCardActions)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(4),
  justifyContent: 'flex-end',
}));

const Title = styled(TypographyWithTooltip)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: 200,
}));
const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  wordBreak: 'break-word',
}));

const TextSection = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(2),
}));
const Author = TextSection;
const UpdateDate = TextSection;

const ViewButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: theme.spacing(1, 2),
}));

//#endregion
