import React, { useMemo, useState } from 'react';

import { DefineProtocol } from 'client/app/apps/protocols/DefineProtocol';
import { DescribeProtocol } from 'client/app/apps/protocols/DescribeProtocol';
import { PersonaliseProtocol } from 'client/app/apps/protocols/PersonaliseProtocol';

const enum ProtocolCreationStage {
  DEFINE_PROTOCOL,
  PERSONALISE_PROTOCOL,
  DESCRIBE_PROTOCOL,
}

const STAGE_TO_COMPONENT: Record<ProtocolCreationStage, React.FC> = {
  [ProtocolCreationStage.DEFINE_PROTOCOL]: DefineProtocol,
  [ProtocolCreationStage.PERSONALISE_PROTOCOL]: PersonaliseProtocol,
  [ProtocolCreationStage.DESCRIBE_PROTOCOL]: DescribeProtocol,
};

export const useProtocolNavigation = () => {
  const [currentStage, setCurrentStage] = useState(ProtocolCreationStage.DEFINE_PROTOCOL);

  const previous = useMemo(() => {
    switch (currentStage) {
      case ProtocolCreationStage.DEFINE_PROTOCOL:
        return {
          label: 'Back to Editing Workflow',
          onClick: () => undefined,
        };
      case ProtocolCreationStage.PERSONALISE_PROTOCOL:
        return {
          label: 'Back to Define Protocol',
          onClick: () => setCurrentStage(ProtocolCreationStage.DEFINE_PROTOCOL),
        };
      case ProtocolCreationStage.DESCRIBE_PROTOCOL:
        return {
          label: 'Back to Personalise Protocol',
          onClick: () => setCurrentStage(ProtocolCreationStage.PERSONALISE_PROTOCOL),
        };
    }
  }, [currentStage]);

  const next = useMemo(() => {
    switch (currentStage) {
      case ProtocolCreationStage.DEFINE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => setCurrentStage(ProtocolCreationStage.PERSONALISE_PROTOCOL),
        };
      case ProtocolCreationStage.PERSONALISE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => setCurrentStage(ProtocolCreationStage.DESCRIBE_PROTOCOL),
        };
      case ProtocolCreationStage.DESCRIBE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => undefined,
        };
    }
  }, [currentStage]);

  return { Content: STAGE_TO_COMPONENT[currentStage], previous, next };
};
