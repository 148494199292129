import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstaceProvider';
import { useSimulationContext } from 'client/app/apps/protocols/context/SimulationProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import { ProtocolValidationIndicatorWithPopover } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ErrorCodes } from 'common/types/errorCodes';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { SimulationIcon } from 'common/ui/icons';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

export const EditProtocolInstanceHeader = () => {
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const { protocolInstance, updatingProtocolInstanceLoading, parametersLoading } =
    useProtocolInstanceContext();
  const {
    onSimulate,
    isSimulating,
    simulateButtonTooltipTitle,
    shouldDisableSimulateButton,
  } = useSimulationContext();
  const { hasElementValidationErrors, formatErrorTitle, handleValidationErrorClick } =
    useStepsContext();

  const handleOnSimulate = useCallback(async () => {
    try {
      await onSimulate();
    } catch (error) {
      await handleCheckConflictError(
        error,
        'protocol',
        ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
      );
    }
  }, [handleCheckConflictError, onSimulate]);

  const protocolInstanceName = protocolInstance.name;

  // TODO: It is not clear yet how are we going to handle non editable behaviours
  const isReadonly = false;
  const isLoading = parametersLoading || updatingProtocolInstanceLoading;

  return (
    <Wrapper>
      <StyledTemplateWorkflowIcon />
      <TypographyWrapper>
        <ProtocolHeader variant="h4">{protocolInstanceName}</ProtocolHeader>
        <Typography variant="body2">Modify the inputs of your protocol</Typography>
      </TypographyWrapper>

      <RightContentWrapper>
        <StatusButtonsWrapper>
          <ProtocolValidationIndicatorWithPopover
            isLoading={isLoading}
            onClickElementError={handleValidationErrorClick}
            formatErrorTitle={formatErrorTitle}
          />
        </StatusButtonsWrapper>
        <Tooltip title={simulateButtonTooltipTitle}>
          <FabWithFX
            busy={isSimulating}
            color="secondary"
            onClick={handleOnSimulate}
            size="medium"
            disabled={
              isReadonly ||
              isLoading ||
              hasElementValidationErrors ||
              shouldDisableSimulateButton
            }
            variant="extended"
          >
            <StyledIcon />
            Simulate
          </FabWithFX>
        </Tooltip>
      </RightContentWrapper>
      {conflictDialog}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
  minHeight: '70px',
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledIcon = styled(SimulationIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const ProtocolHeader = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const StyledTemplateWorkflowIcon = styled(TemplateWorkflowIcon)(() => ({
  color: '#1773C9',
}));

const RightContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginLeft: 'auto',
  alignItems: 'center',
}));

const StatusButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
}));
