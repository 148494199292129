import React, { createContext, FC, useContext } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { v4 as uuid } from 'uuid';

import {
  useQueryProtocol,
  useUpdateProtocol,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolQuery } from 'client/app/gql';
import { ProtocolStep } from 'common/types/Protocol';

type ProtocolContextType = {
  protocol: NonNullable<ProtocolQuery['protocol']['protocol']>;
  steps: ProtocolStep[];
  addNewStep: () => void;
  changeStep: (step: ProtocolStep) => void;
  deleteStep: (stepId: string) => void;
};

export const ProtocolContext = createContext<ProtocolContextType | undefined>(undefined);

type ProtocolProviderProps = {
  protocolId: ProtocolId;
  version: ProtocolVersion;
};

export const useProtocolContext = () => {
  const context = useContext(ProtocolContext);

  if (context === undefined) {
    throw new Error('useProtocolContext must be used within a ProtocolProvider');
  }

  return context;
};

export const ProtocolProvider: FC<ProtocolProviderProps> = ({
  protocolId,
  version,
  children,
}) => {
  const {
    data,
    loading,
    // TODO: Will implement error handling in follow up PRs
    // error
  } = useQueryProtocol(protocolId, version);

  const { handleUpdateProtocol } = useUpdateProtocol(protocolId, version);

  if (loading || !data) return <LinearProgress />;

  const editVersion = data.protocol.editVersion;
  const protocol = data.protocol.protocol;
  const steps = protocol.steps;

  const addNewStep = async () => {
    const steps = protocol.steps;
    const stepsCount = steps.length;
    const newStep = {
      id: uuid(),
      displayName: `New Step ${stepsCount + 1}`,
      inputs: [],
      outputs: [],
    };
    await handleUpdateProtocol({ ...protocol, steps: [...steps, newStep] }, editVersion);
  };

  const deleteStep = async (stepId: string) => {
    const steps = protocol.steps.filter(step => step.id !== stepId);
    await handleUpdateProtocol({ ...protocol, steps }, editVersion);
  };

  const changeStep = async (newStep: ProtocolStep) => {
    const steps = protocol.steps;
    const stepIndex = steps.findIndex(step => step.id === newStep.id);
    const newSteps = [
      ...steps.slice(0, stepIndex),
      newStep,
      ...steps.slice(stepIndex + 1),
    ];
    await handleUpdateProtocol({ ...protocol, steps: newSteps }, editVersion);
  };

  const state = {
    protocol,
    steps,
    addNewStep,
    changeStep,
    deleteStep,
  };

  return <ProtocolContext.Provider value={state}>{children}</ProtocolContext.Provider>;
};
