import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import StepCard from 'client/app/apps/protocols/StepCard/StepCard';
import { useProtocolStep } from 'client/app/apps/protocols/useProtocolStep';
import Button from 'common/ui/components/Button';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';

export const DefineProtocol = () => {
  const { addNewStep, steps } = useProtocolContext();
  const { getStepProps } = useProtocolStep();

  return (
    <Content>
      <SidePanel>
        <Typography variant="h2">Define Protocol</Typography>
        {steps.map(step => (
          <StepCard
            key={step.id}
            {...getStepProps(step)}
            // TODO: I left those out for now
            // Not sure how to best address those. Will update once we get to it.
            onDeleteInput={() => {}}
            onDeleteOutput={() => {}}
          />
        ))}
        <Button variant="primary" color="primary" onClick={addNewStep}>
          + Add a step
        </Button>
      </SidePanel>
      <ContainerWithIntersectionBar
        dense
        noHeader
        content={
          <div>
            <h1>Define protocol</h1>
          </div>
        }
      />
    </Content>
  );
};

const Content = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  minWidth: '350px',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(6, 4),
}));
