import React, { createContext, FC, useContext } from 'react';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstaceProvider';
import {
  StepErrors,
  useProtocolsParamState,
  useStepsWithErrors,
} from 'client/app/apps/protocols/lib/utils';
import { ElementErrorData } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import { ProtocolStep } from 'common/types/Protocol';

type StepsContextType = {
  steps: ProtocolStep[];
  stepErrors: StepErrors;
  selectedStep: ProtocolStep;
  selectedStepErrors: ElementErrorData[] | undefined;
  hasElementValidationErrors: boolean;
  handleSelectStep: (step: ProtocolStep) => void;
  handleValidationErrorClick: (elementId: string) => void;
  formatErrorTitle: (elementId: string) => string;
};

export const StepsContext = createContext<StepsContextType | undefined>(undefined);

export const useStepsContext = () => {
  const context = useContext(StepsContext);

  if (context === undefined) {
    throw new Error('useStepsContext must be used within a StepsProvider');
  }

  return context;
};

export const StepsProvider: FC = ({ children }) => {
  const { steps, protocol } = useProtocolInstanceContext();

  const { selectedStep, handleSelectStep } = useProtocolsParamState(steps);
  const stepErrors = useStepsWithErrors(protocol);

  const getStepForErroredElement = (elementId: string): ProtocolStep | undefined => {
    const erroredStepId = [...stepErrors.entries()].find(([_, elementErrors]) => {
      return elementErrors.some(elementError => elementError.elementId === elementId);
    })?.[0];
    return steps.find(step => step.id === erroredStepId);
  };

  const handleValidationErrorClick = (elementId: string) => {
    const erroredStep = getStepForErroredElement(elementId);
    if (erroredStep) {
      handleSelectStep(erroredStep);
    }
  };

  const formatErrorTitle = (elementId: string) => {
    const erroredStep = getStepForErroredElement(elementId);
    return erroredStep?.displayName ?? '';
  };
  const selectedStepErrors = stepErrors.get(selectedStep.id);
  const hasElementValidationErrors = [...stepErrors.entries()].some(
    ([_, elementErrors]) => elementErrors.some(ele => ele.severity === 'error'),
  );

  const state = {
    steps,
    stepErrors,
    selectedStep,
    selectedStepErrors,
    hasElementValidationErrors,
    handleSelectStep,
    formatErrorTitle,
    handleValidationErrorClick,
  };

  return <StepsContext.Provider value={state}>{children}</StepsContext.Provider>;
};
